import { type Middleware } from '@nuxt/types';
import { useUserStore } from '~/src/framework/store/user';
import {
  createDefaultClassroom,
  useClassroomStore
} from '~/src/framework/store/classroom/classroom';
import { useOfflineErrorDialogStore } from '~/src/framework/store/errorHandling/offlineErrorDialog';
import { useNotAuthenticatedErrorDialogStore } from '~/src/framework/store/errorHandling/notAuthenticatedErrorDialog';
import { jukuConfigRepo } from '~/src/infrastructure/repositories/user/jukuConfig';
import { useJukuConfigStore } from '~/src/framework/store/jukuConfig';
import { pagesPath, RouteName } from '~/src/framework/plugins/$path';
import { useNotAuthenticatedClassroomErrorDialogStore } from '~/src/framework/store/errorHandling/notAuthenticatedClassroomErrorDialog';
import { useClassroomOptionsStore } from '~/src/framework/store/classroom/classroomOptions';
import { omitProperties } from '~/src/shared/objectOperationUtil';
import { LocalStorageKey } from '~/src/domain/interfaces/browser/localStorage';
import { EnvVars } from '~/env/env';

/**
 * ユーザーがログインしているかどうかのチェック
 * excludedNameを含むパスへアクセスしたときは認証されていなくてもリダイレクトしない
 */
const excludedRouteNames: (string | RouteName)[] = [
  pagesPath.sign_in.classroom.$name(),
  pagesPath.sign_in.teacher.$name()
];

const isExcludedRouteName = (name: RouteName | string) =>
  excludedRouteNames.includes(name);

export default <Middleware>(
  async function ({
    route,
    redirect,
    pinia,
    $gtag,
    $auth,
    $localStorage,
    $jukuRepo,
    $managementUserRepo,
    $config,
    $logger
  }) {
    const userStore = useUserStore(pinia);
    const classroomStore = useClassroomStore(pinia);
    const classroomOptionsStore = useClassroomOptionsStore(pinia);
    const currentRouteName = route.name;
    const { STAGE_NAME } = $config as EnvVars.T;
    if (!currentRouteName || isExcludedRouteName(currentRouteName)) return;

    try {
      /**
       * ユーザーをセット
       */
      const { username } = await $auth.currentAuthenticatedUser();
      const user = (await $managementUserRepo.fetchByUsername(username))._unsafeUnwrap();
      userStore.set(user);
      // eslint-disable-next-line no-console
      $logger.debug('store user', omitProperties(user, ['emails', 'name', 'furigana']));

      /**
       * JukuConfigをセット
       */
      const jukuConfigStore = useJukuConfigStore(pinia);
      (await jukuConfigRepo.fetch(user.jukuId)).match(
        data => {
          jukuConfigStore.set(data);
        },
        _e => {}
      );

      /**
       * 選択可能な教室(複数)をセット
       */
      if (user.__typename === 'Teacher') {
        classroomOptionsStore.set([user.mainClassroom, ...user.subClassrooms]);
      } else if (user.__typename === 'Manager' && user.juku.classrooms) {
        classroomOptionsStore.set([...user.juku.classrooms]);
      } else {
        classroomOptionsStore.set([]);
      }

      /**
       * ログインしている教室をセット
       */
      const storeClassroomId = $localStorage.getItem<string>(
        LocalStorageKey.currentClassroomId
      );
      if (storeClassroomId) {
        // localStorageにclassroomIdが保存されている場合
        const storeClassroom = classroomOptionsStore
          .get()
          .find(classroom => classroom.id === parseInt(storeClassroomId));
        if (!storeClassroom) return;
        classroomStore.set(storeClassroom);
      } else if (user.__typename === 'Teacher') {
        $localStorage.setItem(
          LocalStorageKey.currentClassroomId,
          String(user.mainClassroom.id)
        );
        classroomStore.set(user.mainClassroom);
        // eslint-disable-next-line no-console
        $logger.debug('store classroom from storage');
      } else if (user.__typename === 'Manager') {
        // storeのclassroomをリセット
        classroomStore.set(createDefaultClassroom());
        // 既に初期画面、生徒アカウント画面にいる場合にリダイレクトさせると、無限ループになる為、条件分岐
        if (
          route.path !== pagesPath.$url().path &&
          route.path !== pagesPath.home.guidance_schedules.$url().path
        ) {
          // FIXME: なんかちょっとタイミングをずらさないといい感じにリダイレクトされない
          setTimeout(() => redirect(pagesPath.home.guidance_schedules.$url().path));
        }
      } else {
        redirect(pagesPath.sign_in.teacher.$url().path);
        $logger.error('middleware 教室セット時、予期しないuserのハンドリング未定義');
      }

      if (STAGE_NAME !== 'local') {
        $logger.identify(user.username, {
          id: user.id,
          username: user.username,
          jukuId: user.jukuId,
          role: userStore.userRole
        });
        /*
         * googleAnalyticsにjuku_codeを送信(ローカル環境は送信しない)
         * jukuの取得でawaitしない為に非同期処理で実行
         */
        $jukuRepo.fetch(user.jukuId).then(result => {
          result.match(
            ({ jukuCode }) => {
              $gtag('set', 'user_properties', {
                juku_code: jukuCode,
                classroom_id: classroomStore.get().id
              });
            },
            _e => {}
          );
        });
      }
    } catch (e: any) {
      $logger.error(e);

      if (userStore.get().id !== -1 && e === 'The user is not authenticated') {
        // 認証エラーかつstoreにユーザーがセットされている場合、認証が切れたことを通知するダイアログを開く
        const notAuthenticatedErrorDialogStore =
          useNotAuthenticatedErrorDialogStore(pinia);
        notAuthenticatedErrorDialogStore.open();
      } else if (e.message === 'ClassroomLoginError') {
        // 教室認証エラーかつstoreにユーザーがセットされている場合、教室認証が切れたことを通知するダイアログを開く
        const notAuthenticatedClassroomErrorDialogStore =
          useNotAuthenticatedClassroomErrorDialogStore(pinia);
        notAuthenticatedClassroomErrorDialogStore.open();
      } else if (typeof e === 'object' && 'message' in e) {
        // オフラインや回線等の影響でサーバーと通信できないときのハンドリング
        if (e.message === 'Failed to fetch') {
          const offlineErrorDialogStore = useOfflineErrorDialogStore(pinia);
          offlineErrorDialogStore.open();
        }
      } else {
        // 上記のパターン以外の場合はsentryに通知し、ログイン画面に戻す
        $logger.captureException(e);
        redirect(pagesPath.sign_in.teacher.$url().path);
        $localStorage.removeItem(LocalStorageKey.currentClassroomId);
      }
    }
  }
);
