import { render, staticRenderFns } from "./TextMarkedLeft.vue?vue&type=template&id=4ebc5115&scoped=true"
import script from "./TextMarkedLeft.vue?vue&type=script&setup=true&lang=ts"
export * from "./TextMarkedLeft.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TextMarkedLeft.vue?vue&type=style&index=0&id=4ebc5115&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.23.7__cache-loader@4.1.0_webpack@4_ybmjhwcrqye5ajy6w6zujdoyr4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ebc5115",
  null
  
)

export default component.exports