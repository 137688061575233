import { ref } from '@nuxtjs/composition-api';
import type { InjectionKey } from 'vue';
import { AuthIncorrectUsernameOrPassword } from '../errors/auth';
import { i18n } from '~/src/shared/locales/i18n';
import {
  ApiAuthenticationError,
  ApiAuthorizationError,
  ApiRoleError,
  ApiOperationRuleError,
  ApiClassroomLoginError,
  ApiProcessDataTooLargeError,
  ApiFatalError,
  ApiInternalServerError,
  ApiMaintenanceMode,
  ApiParseError,
  ApiDuplicateError,
  ApiInvalidTypeError,
  ApiNotExistError,
  ApiUnexpectedError
} from '~/src/shared/errors/api';

export type ErrorDialog = ReturnType<typeof useErrorDialog>;

export const ErrorDialogKey: InjectionKey<ErrorDialog> = Symbol('ErrorDialog');

export const useErrorDialog = () => {
  const isOpenedErrorDialog = ref(false);
  const openErrorDialog = (message?: string) => {
    if (message !== undefined) errorMessage.value = message;
    isOpenedErrorDialog.value = true;
  };
  const closeErrorDialog = () => {
    isOpenedErrorDialog.value = false;
  };
  const errorMessage = ref(i18n.errors.other.$t());

  const getErrorMessage = (error: unknown): string => {
    if (typeof error === 'string') return i18n.errors.unexpected.$t();
    if (typeof error !== 'object' || error === null) return i18n.errors.unexpected.$t();

    const isError = (error: unknown): error is { message: string } =>
      typeof error === 'object' && error !== null && 'message' in error;

    if (isError(error)) {
      return _getErrorMessage(error);
    } else {
      return i18n.errors.unexpected.$t();
    }
  };

  return {
    isOpenedErrorDialog,
    openErrorDialog,
    closeErrorDialog,
    errorMessage,
    getErrorMessage
  };
};

const _getErrorMessage = (e: { message: string }): string => {
  // prettier-ignore
  if (e instanceof AuthIncorrectUsernameOrPassword) return i18n.errors.authorizeException.$t();

  // prettier-ignore
  if (e instanceof ApiAuthenticationError) return i18n.errors.authentication.$t();

  // prettier-ignore
  if (e instanceof ApiAuthorizationError) return i18n.errors.authorizeException.$t();

  // prettier-ignore
  if (e instanceof ApiRoleError) return i18n.errors.roleError.$t();

  // prettier-ignore
  if (e instanceof ApiOperationRuleError) return i18n.errors.unexpected.$t();

  // prettier-ignore
  if (e instanceof ApiClassroomLoginError) return i18n.errors.classroomLoginError.$t();

  // prettier-ignore
  if (e instanceof ApiProcessDataTooLargeError) return i18n.errors.unexpected.$t();

  // prettier-ignore
  if (e instanceof ApiFatalError) return i18n.errors.fatal.$t();

  // prettier-ignore
  if (e instanceof ApiInternalServerError) return i18n.errors.unexpected.$t();

  // prettier-ignore
  if (e instanceof ApiMaintenanceMode) return i18n.errors.maintenanceMode.$t();

  // prettier-ignore
  if (e instanceof ApiParseError) return i18n.errors.parse.$t();

  // prettier-ignore
  if (e instanceof ApiDuplicateError) return i18n.errors.duplicate.$t();

  // prettier-ignore
  if (e instanceof ApiInvalidTypeError) return i18n.errors.invalidType.$t();

  // prettier-ignore
  if (e instanceof ApiNotExistError) return i18n.errors.notExist.$t();

  // prettier-ignore
  if (e instanceof ApiUnexpectedError) return i18n.errors.unexpected.$t();

  // NOTE: ApiErrorに移行し終わればこの分岐は全て消える(instanceofに変更される)
  switch (e.message) {
    case 'ParseError':
      return i18n.errors.parse.$t();
    case 'AuthenticationError':
      return i18n.errors.authentication.$t();
    case 'NotExistError':
      return i18n.errors.notExist.$t();
    case 'DuplicateError':
      return i18n.errors.duplicate.$t();
    case 'FatalError':
      return i18n.errors.fatal.$t();
    case 'InvalidTypeError':
      return i18n.errors.invalidType.$t();
    case 'MaintenanceMode':
      return i18n.errors.maintenanceMode.$t();
    case 'ClassroomLoginError':
      return i18n.errors.classroomLoginError.$t();
    case 'RoleError':
      return i18n.errors.roleError.$t();
    case 'InternalServerError':
      return i18n.errors.unexpected.$t();
    default:
      return i18n.errors.unexpected.$t();
  }
};
