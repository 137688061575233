import { LocalLogger } from '~/src/infrastructure/logger/localLogger';
import { Logger } from '~/src/infrastructure/logger/logger';
import { DevLogger } from '~/src/infrastructure/logger/devLogger';
import { useUserStore } from '~/src/framework/store/user';
// FIXME: 設計変更に伴ってlogger objectを一時的にexportしている。将来的には全てDIされたものを使う (Nuxtのctxから参照する)
// eslint-disable-next-line import/no-mutable-exports
export var logger;
var LoggerProvider = function LoggerProvider(_ref, provide) {
  var $config = _ref.$config;
  // eslint-disable-next-line camelcase
  var _ref2 = $config,
    STAGE_NAME = _ref2.STAGE_NAME,
    npm_package_name = _ref2.npm_package_name,
    SENTRY_DSN = _ref2.SENTRY_DSN;
  var user = useUserStore();

  // NOTE: logger.initSessionReplay is lazy called on middleware
  // logger.initSessionReplay()

  provide('logger', logger = function () {
    var stageName = STAGE_NAME;
    // eslint-disable-next-line camelcase
    var appVersion = npm_package_name;
    var sentryDsn = SENTRY_DSN;
    switch (stageName) {
      case 'production':
        return new Logger({
          stageName: stageName,
          appVersion: appVersion,
          sentryDsn: sentryDsn
        });
      case 'development':
      case 'staging':
        return new DevLogger({
          stageName: stageName,
          appVersion: appVersion,
          sentryDsn: sentryDsn
        });
      case 'local':
        return new LocalLogger();
      default:
        {
          var _exhaustiveCheck = stageName;
          console.warn("Unexpected stageName: ".concat(_exhaustiveCheck));
          return new LocalLogger();
        }
    }
  }());
  logger.initErrorTracker(user.get().username);
};
export default LoggerProvider;