import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=73719780&scoped=true"
import script from "./AppHeader.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppHeader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=73719780&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.23.7__cache-loader@4.1.0_webpack@4_ybmjhwcrqye5ajy6w6zujdoyr4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73719780",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginStatusLabel: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/domain/fragments/user/student/LoginStatusLabel.vue').default,UserLogin: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/domain/fragments/user/account/UserLogin.vue').default})
