import { type Middleware } from '@nuxt/types';
import { useTeacherTaskChecksStore } from '~/src/framework/store/teacherTaskChecks';
import { teacherTaskCheckRepo } from '~/src/infrastructure/repositories/reco/task/teacherTaskCheck';

/**
 * studentNavBtnに未完了のタスクがある場合に、赤ポチを表示するためのmiddlewareでreposを叩くように実装
 */
export default <Middleware>function ({ route, pinia }) {
  if (!route.params?.studentId) return;
  const teacherTaskChecksStore = useTeacherTaskChecksStore(pinia);

  (async () => {
    const studentId = route.params.studentId ? parseInt(route.params.studentId) : null;
    if (!studentId) return;

    teacherTaskChecksStore.set(
      (await teacherTaskCheckRepo.fetchByStudentId(studentId)).unwrapOr([])
    );
  })();
};
