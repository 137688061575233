import { aimAtReportRepo } from '~/src/infrastructure/repositories/aim/aimAtReport';
import { studyHistoryRepo } from '~/src/infrastructure/repositories/aim/studyHistory';
import { todoProgressRepo } from '~/src/infrastructure/repositories/aim/todoProgress';
import { optionsForLeftKomaInfoRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceKoma/optionForLeftKomaInfo';
import { optionsForExamRepo } from '~/src/infrastructure/repositories/common/options/optionsForExam';
import { optionsForGuidanceKomaRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceKoma/optionsForGuidanceKoma';
import { optionsForGuidanceKomaTextbookStudyRepo } from '~/src/infrastructure/repositories/common/options/optionsForGuidanceKomaTextbookStudy';
import { optionsForStudentContractRegistrationRepo } from '~/src/infrastructure/repositories/reco/guidance/contract/optionsForStudentContractRegistration';
import { optionsForStudyPlanRepo } from '~/src/infrastructure/repositories/common/options/optionsForStudyPlan';
import { optionsForStudyPlanFormRepo } from '~/src/infrastructure/repositories/common/options/optionsForStudyPlanForm';
import { optionsForTeacherShiftKomaRepo } from '~/src/infrastructure/repositories/reco/guidance/teacherShift/optionsForTeacherShiftKoma';
import { optionsForTextbookRepo } from '~/src/infrastructure/repositories/common/options/optionsForTextbook';
// contents
import { studyPlanRepo } from '~/src/infrastructure/repositories/reco/contents/studyPlan/studyPlan';
import { studyPlanSubjectInfoRepo } from '~/src/infrastructure/repositories/reco/contents/studyPlan/studyPlanSubjectInfo';
import { weeklyStudyPlanRepo } from '~/src/infrastructure/repositories/reco/contents/studyPlan/weeklyStudyPlan';
import { weeklyStudyPlanMemoRepo } from '~/src/infrastructure/repositories/reco/contents/studyPlan/weeklyStudyPlanMemo';
import { studentTagRepo } from '~/src/infrastructure/repositories/reco/contents/tag/studentTag';
import { studentTextbookRepo } from '~/src/infrastructure/repositories/reco/contents/textbook/studentTextbook';
import { textbookTemplateRepo } from '~/src/infrastructure/repositories/reco/contents/textbook/textbookTemplate';
import { totalTextbookStudyRepo } from '~/src/infrastructure/repositories/reco/contents/textbook/totalTextbookStudy';
import { examSubjectInfoRepo } from '~/src/infrastructure/repositories/reco/exam/examSubjectInfo';
import { originalExamRepo } from '~/src/infrastructure/repositories/reco/exam/original/originalExam';
import { originalExamSubjectRepo } from '~/src/infrastructure/repositories/reco/exam/original/originalExamSubject';
import { additionalSchoolExamSubjectRepo } from '~/src/infrastructure/repositories/reco/exam/school/additionalSchoolExamSubject';
import { defaultSchoolExamSubjectRepo } from '~/src/infrastructure/repositories/reco/exam/school/defaultSchoolExamSubject';
import { schoolExamRepo } from '~/src/infrastructure/repositories/reco/exam/school/schoolExam';
import { schoolExamSubjectRepo } from '~/src/infrastructure/repositories/reco/exam/school/schoolExamSubject';
import { fixedTeacherShiftKomaRepo } from '~/src/infrastructure/repositories/reco/guidance/teacherShift/fixedTeacherShiftKoma';
import { guidanceDateRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceDate';
import { guidanceHistoryRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceHistory';
import { guidanceKomaRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceKoma/guidanceKoma';
import { guidancePolicyRepo } from '~/src/infrastructure/repositories/reco/guidance/guidancePolicy';
import { guidanceKomaTextbookStudyRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceReport/guidanceKomaTextbookStudy';
import { guidanceReportRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceReport/guidanceReport';
import { guidanceReviewRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceReport/guidanceReview';
import { guidanceReviewMasterRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceReport/guidanceReviewMaster';
import { parentReportRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceReport/parentReport';
import { parentReportTagRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceReport/parentReportTag';
import { guidanceCustomTestRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceTest/guidanceCustomTest';
import { guidanceJukuTestRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceTest/guidanceJukuTest';
import { guidanceJukuTestTemplateRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceTest/guidanceJukuTestTemplate';
import { guidanceJukuTestTemplateFolderRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceTest/guidanceJukuTestTemplateFolder';
import { guidanceJukuTestTemplateTopFolderRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceTest/guidanceJukuTestTemplateTopFolder';
import { guidanceTaskRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceTask';
import { komaTemplateRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceKoma/komaTemplate';
import { leftKomaInfoRepo } from '~/src/infrastructure/repositories/reco/guidance/guidanceKoma/leftKomaInfo';
import { studentContractRepo } from '~/src/infrastructure/repositories/reco/guidance/contract/studentContract';
import { teacherShiftRepo } from '~/src/infrastructure/repositories/reco/guidance/teacherShift/teacherShift';
import { teacherShiftKomaRepo } from '~/src/infrastructure/repositories/reco/guidance/teacherShift/teacherShiftKoma';
import { subjectRepo } from '~/src/infrastructure/repositories/reco/subject/subject';
import { teacherTaskRepo } from '~/src/infrastructure/repositories/reco/task/teacherTask';
import { teacherTaskCheckRepo } from '~/src/infrastructure/repositories/reco/task/teacherTaskCheck';
import { accessRoomRepo } from '~/src/infrastructure/repositories/user/accessRoom';
import { jukuRepo } from '~/src/infrastructure/repositories/user/juku';
import { jukuConfigRepo } from '~/src/infrastructure/repositories/user/jukuConfig';
import { managementUserRepo } from '~/src/infrastructure/repositories/user/managementUser';
import { studentRepo } from '~/src/infrastructure/repositories/user/student';
import { teacherRepo } from '~/src/infrastructure/repositories/user/teacher';
import { teacherSharedCommentRepo } from '~/src/infrastructure/repositories/user/teacherSharedComment';

// interfaces

import { textbookTagRepo } from '~/src/infrastructure/repositories/reco/contents/tag/textbookTag';
import { classroomGuidanceDateRepo } from '~/src/infrastructure/repositories/reco/guidance/classroomGuidanceDate';
var repositoryProvider = function repositoryProvider(_ref, provide) {
  var $config = _ref.$config;
  // aim
  provide('aimAtReportRepo', aimAtReportRepo);
  provide('studyHistoryRepo', studyHistoryRepo);
  provide('todoProgressRepo', todoProgressRepo);

  // common
  provide('optionsForLeftKomaInfoRepo', optionsForLeftKomaInfoRepo);
  provide('optionsForExamRepo', optionsForExamRepo);
  provide('optionsForGuidanceKomaRepo', optionsForGuidanceKomaRepo);
  // prettier-ignore
  provide('optionsForGuidanceKomaTextbookStudyRepo', optionsForGuidanceKomaTextbookStudyRepo);

  // prettier-ignore
  provide('optionsForStudentContractRegistrationRepo', optionsForStudentContractRegistrationRepo);
  provide('optionsForStudyPlanRepo', optionsForStudyPlanRepo);
  provide('optionsForStudyPlanFormRepo', optionsForStudyPlanFormRepo);
  provide('optionsForTeacherShiftKomaRepo', optionsForTeacherShiftKomaRepo);
  provide('optionsForTextbookRepo', optionsForTextbookRepo);

  // reco
  // contents
  provide('studyPlanRepo', studyPlanRepo);
  provide('studyPlanSubjectInfoRepo', studyPlanSubjectInfoRepo);
  provide('weeklyStudyPlanRepo', weeklyStudyPlanRepo);
  provide('weeklyStudyPlanMemoRepo', weeklyStudyPlanMemoRepo);
  provide('studentTagRepo', studentTagRepo);
  provide('textbookTagRepo', textbookTagRepo);
  provide('studentTextbookRepo', studentTextbookRepo);
  provide('textbookTemplateRepo', textbookTemplateRepo);
  provide('totalTextbookStudyRepo', totalTextbookStudyRepo);
  // exam
  provide('originalExamRepo', originalExamRepo);
  provide('originalExamSubjectRepo', originalExamSubjectRepo);
  provide('additionalSchoolExamSubjectRepo', additionalSchoolExamSubjectRepo);
  provide('defaultSchoolExamSubjectRepo', defaultSchoolExamSubjectRepo);
  provide('schoolExamRepo', schoolExamRepo);
  provide('schoolExamSubjectRepo', schoolExamSubjectRepo);
  provide('examSubjectInfoRepo', examSubjectInfoRepo);
  // guidance
  provide('guidanceKomaTextbookStudyRepo', guidanceKomaTextbookStudyRepo);
  provide('guidanceReportRepo', guidanceReportRepo);
  provide('guidanceReviewRepo', guidanceReviewRepo);
  provide('guidanceReviewMasterRepo', guidanceReviewMasterRepo);
  provide('parentReportRepo', parentReportRepo);
  provide('parentReportTagRepo', parentReportTagRepo);
  provide('guidanceCustomTestRepo', guidanceCustomTestRepo);
  provide('guidanceJukuTestRepo', guidanceJukuTestRepo);
  provide('guidanceJukuTestTemplateRepo', guidanceJukuTestTemplateRepo);
  provide('guidanceJukuTestTemplateFolderRepo', guidanceJukuTestTemplateFolderRepo);
  provide('guidanceJukuTestTemplateTopFolderRepo', guidanceJukuTestTemplateTopFolderRepo);
  provide('teacherShiftRepo', teacherShiftRepo);
  provide('teacherShiftKomaRepo', teacherShiftKomaRepo);
  provide('fixedTeacherShiftKomaRepo', fixedTeacherShiftKomaRepo);
  provide('guidanceDateRepo', guidanceDateRepo);
  provide('guidanceHistoryRepo', guidanceHistoryRepo);
  provide('guidanceKomaRepo', guidanceKomaRepo);
  provide('guidancePolicyRepo', guidancePolicyRepo);
  provide('komaTemplateRepo', komaTemplateRepo);
  provide('leftKomaInfoRepo', leftKomaInfoRepo);
  provide('studentContractRepo', studentContractRepo);
  provide('classroomGuidanceDateRepo', classroomGuidanceDateRepo);
  provide('guidanceTaskRepo', guidanceTaskRepo);
  // subject
  provide('subjectRepo', subjectRepo);
  // task
  provide('teacherTaskRepo', teacherTaskRepo);
  provide('teacherTaskCheckRepo', teacherTaskCheckRepo);

  // user
  provide('accessRoomRepo', accessRoomRepo);
  provide('jukuRepo', jukuRepo);
  provide('jukuConfigRepo', jukuConfigRepo);
  provide('managementUserRepo', managementUserRepo);
  provide('studentRepo', studentRepo);
  provide('teacherRepo', teacherRepo);
  provide('teacherSharedCommentRepo', teacherSharedCommentRepo);
};
export default repositoryProvider;