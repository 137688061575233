import { ref } from 'vue';
import { defineStore } from 'pinia';

/**
 * cognitoの'ClassroomLoginError'エラー時に、
 * storeにユーザーがセットされていれば、
 * ユーザーがアプリ操作時にリフレッシュトークンが切れたと判定し、
 * 教室認証が切れたことを通知するダイアログを開く。
 * 認証情報とストアのチェックはmiddlewareで行う。
 */

export const useNotAuthenticatedClassroomErrorDialogStore = defineStore(
  'notAuthenticatedClassroomErrorDialog',
  () => {
    const isOpened = ref(false);

    const get = (): Readonly<boolean> => isOpened.value;

    const open = (): void => {
      isOpened.value = true;
    };
    const close = (): void => {
      isOpened.value = false;
    };

    return { get, open, close };
  }
);
