import { render, staticRenderFns } from "./ErrorDialog.vue?vue&type=template&id=57003dff&scoped=true"
import script from "./ErrorDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./ErrorDialog.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ErrorDialog.vue?vue&type=style&index=0&id=57003dff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.23.7__cache-loader@4.1.0_webpack@4_ybmjhwcrqye5ajy6w6zujdoyr4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57003dff",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextBtn: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/shared/components/buttons/TextBtn.vue').default,PlainDialog: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/shared/components/dialogs/PlainDialog.vue').default})
