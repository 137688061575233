import { createAuth } from '~/src/infrastructure/auth';
// eslint-disable-next-line import/no-mutable-exports
export var auth;
var AuthProvider = function AuthProvider(_ref, provide) {
  var $config = _ref.$config;
  var _ref2 = $config,
    AWS_AMPLIFY_AUTH_REGION = _ref2.AWS_AMPLIFY_AUTH_REGION,
    AWS_AMPLIFY_AUTH_USER_POOL_ID = _ref2.AWS_AMPLIFY_AUTH_USER_POOL_ID,
    AWS_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID = _ref2.AWS_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
    AWS_AMPLIFY_AUTH_ID_POOL_ID = _ref2.AWS_AMPLIFY_AUTH_ID_POOL_ID;
  provide('auth', auth = createAuth({
    AWS_AMPLIFY_AUTH_REGION: AWS_AMPLIFY_AUTH_REGION,
    AWS_AMPLIFY_AUTH_USER_POOL_ID: AWS_AMPLIFY_AUTH_USER_POOL_ID,
    AWS_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID: AWS_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
    AWS_AMPLIFY_AUTH_ID_POOL_ID: AWS_AMPLIFY_AUTH_ID_POOL_ID
  }));
};
export default AuthProvider;