import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { legacyApolloClient } from '~/src/infrastructure/apis/graphql/legacyClient';
import {
  getStudentTextbook,
  getStudentTextbooksByStudentId,
  getStudentTextbooksOfOtherStudents
} from '~/src/infrastructure/apis/graphql/query/queries';
import {
  getStudentTextbook as getStudentTextbookLegacy,
  getStudentTextbooksByStudentId as getStudentTextbooksByStudentIdLegacy
} from '~/src/infrastructure/apis/graphql/query/legacy/queries';
import {
  createStudentTextbook,
  deleteStudentTextbook,
  updateStudentTextbook
} from '~/src/infrastructure/apis/graphql/query/mutations';
import { updateStudentTextbook as updateStudentTextbookLegacy } from '~/src/infrastructure/apis/graphql/query/legacy/mutations';
import {
  StudentTextbookCreateInput,
  Mutation,
  StudentTextbookUpdateInput,
  TextbookSearchOptions,
  StudentTextbookDeleteInput,
  Query
} from '~/src/domain/entities/schema';
import {
  Mutation as MutationLegacy,
  StudentTextbookUpdateInput as StudentTextbookUpdateInputLegacy,
  TextbookSearchOptions as TextbookSearchOptionsLegacy,
  Query as QueryLegacy
} from '~/src/domain/entities/schema.legacy';
import { StudentTextbookRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/contents/textbook/studentTextbook.interface';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { DataNotExistError } from '~/src/shared/errors/errors';

export const studentTextbookRepo: StudentTextbookRepositoryInterface = {
  /**
   * 教材単体を取得する.
   * @param id 取得する教材のstudentId
   */
  fetch: async (textbookId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { textbookId },
      query: gql(getStudentTextbook)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('getStudentTextbook', data.getStudentTextbook);
    return ok({ ...data.getStudentTextbook });
  },

  /**
   * 教材一覧を取得する.
   * @param studentId 取得する生徒のstudentId
   */
  fetchByStudentId: async (studentId: number, options: TextbookSearchOptions) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, options },
      query: gql(getStudentTextbooksByStudentId)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('getStudentTextbooksByStudentId input', studentId, { ...options });
    logger?.debug(
      'getStudentTextbooksByStudentId data',
      data.getStudentTextbooksByStudentId
    );
    return ok([...data.getStudentTextbooksByStudentId]);
  },

  // /**
  //  * 同じ学校の他の生徒の教材一覧を取得する.
  //  * @param studentId 取得する生徒のstudentId
  //  * @param jukuId 取得する生徒のjukuId
  //  * @param options 取得する生徒のstudentGradeId,semesterId
  //  */
  fetchByOtherStudents: async (studentId: number, options: TextbookSearchOptions) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, options },
      query: gql(getStudentTextbooksOfOtherStudents)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug(
      'getStudentTextbooksOfOtherStudents',
      data.getStudentTextbooksOfOtherStudents
    );
    return ok([...data.getStudentTextbooksOfOtherStudents]);
  },

  /**
   * 教材新規作成
   */
  create: async (createStudentTextbookInput: StudentTextbookCreateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: createStudentTextbookInput },
      mutation: gql(createStudentTextbook)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('createStudentTextbook input', createStudentTextbookInput);
    logger?.debug('createStudentTextbook data', data.createStudentTextbook);
    return ok({ ...data.createStudentTextbook });
  },

  /**
   * 教材編集
   */
  update: async (updateStudentTextbookInput: StudentTextbookUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: updateStudentTextbookInput },
      mutation: gql(updateStudentTextbook)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('updateStudentTextbookInput input', updateStudentTextbookInput);
    logger?.debug('updateStudentTextbook data', data.updateStudentTextbook);
    return ok({ ...data.updateStudentTextbook });
  },

  /**
   * 教材削除
   */
  delete: async (deleteStudentTextbookInput: StudentTextbookDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: deleteStudentTextbookInput },
      mutation: gql(deleteStudentTextbook)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('deleteStudentTextbookInput input', deleteStudentTextbookInput);
    logger?.debug('deleteStudentTextbook data', data.deleteStudentTextbook);
    return ok({ ...data.deleteStudentTextbook });
  },

  /*
   * legacyMethods
   */
  legacy: {
    fetch: async (textbookId: number) => {
      const { data, errors } = await legacyApolloClient.query<QueryLegacy>({
        variables: { textbookId },
        query: gql(getStudentTextbookLegacy)
      });
      if (errors?.length) return err(errors);
      if (!data) return err(new DataNotExistError());
      logger?.debug('getStudentTextbook', data.getStudentTextbook);
      return ok({ ...data.getStudentTextbook });
    },

    fetchByStudentId: async (studentId: number, options: TextbookSearchOptionsLegacy) => {
      const { data, errors } = await legacyApolloClient.query<QueryLegacy>({
        variables: { studentId, options },
        query: gql(getStudentTextbooksByStudentIdLegacy)
      });
      if (errors?.length) return err(errors);
      if (!data) return err(new DataNotExistError());
      logger?.debug('getStudentTextbooksByStudentId input', studentId, { ...options });
      logger?.debug(
        'getStudentTextbooksByStudentId data',
        data.getStudentTextbooksByStudentId
      );
      return ok([...data.getStudentTextbooksByStudentId]);
    },

    update: async (updateStudentTextbookInput: StudentTextbookUpdateInputLegacy) => {
      const { data, errors } = await legacyApolloClient.mutate<MutationLegacy>({
        variables: { input: updateStudentTextbookInput },
        mutation: gql(updateStudentTextbookLegacy)
      });
      if (errors?.length) return err(errors);
      if (!data) return err(new DataNotExistError());
      logger?.debug('updateStudentTextbookInput input', updateStudentTextbookInput);
      logger?.debug('updateStudentTextbook data', data.updateStudentTextbook);
      return ok({ ...data.updateStudentTextbook });
    }
  }
} as const;
